import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
//import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import Icon1ImageSrc from "images/colosseum.svg";
import Icon2ImageSrc from "images/lesson.svg";
import Icon3ImageSrc from "images/license.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  //const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Eleganza & Innovazione <HighlightedText>Ricostruzione Unghie</HighlightedText></>}
        description="Charm Nails è un'azienda dinamica, focalizzata sulle esigenze dei professionisti delle unghie e del mercato globale, sempre in cerca di novità. Offre prodotti di alta qualità con un marketing efficace e un servizio efficiente. Il brand si distingue per il suo packaging elegante, prezzi competitivi, ampia diffusione, formazione avanzata, supporto tecnico, e una vasta gamma di prodotti e servizi per i saloni di bellezza."
        imageSrc="https://www.nailshopping.it/wp-content/uploads/ALE09155-1200x800.jpg"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Vai al Catalogo"
        watchVideoButtonText="Il Team"
      />
      <MainFeature
        subheading={<Subheading>Fondata nel 2001</Subheading>}
        heading={
          <>
            Sul mercato da
            <wbr /> <HighlightedText>oltre 20 anni</HighlightedText>
          </>
        }
        description={
          <Description>
            Dopo una ricerca e test approfonditi da chimici esperti, Charm Nails ha creato una linea innovativa di prodotti professionali per la ricostruzione unghie.<br /> La gamma include UV Gel, Gel Colorati, Polveri Acriliche, e oltre 2000 prodotti vari, tutti clinicamente testati e con ingredienti naturali.
            <br />
            <br />
            Charm Nails ringrazia per la considerazione come partner affidabile nella cura delle unghie.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Offerte"
        imageSrc={
          "https://www.nailshopping.it/wp-content/uploads/Schermata-2021-10-31-alle-19.42.46.png"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Il nostro <HighlightedText>catalogo.</HighlightedText>
          </>
        }
      />
      <Features
        heading={
          <>
            Un servizio <HighlightedText>Eccellente.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: Icon1ImageSrc,
            title: "A Roma e non solo",
            description: "sede principale via di Portonaccio 184",
            url: "https://www.nailshopping.it/chi-siamo/"
          },
          {
            imageSrc: Icon2ImageSrc,
            title: "Corsi Professionali",
            description: "siamo anche Accademia",
            url: "https://www.nailshopping.it/product-category/corsi-rome-nail-academy/"
          },
          {
            imageSrc: Icon3ImageSrc,
            title: "Rilascio Licenze",
            description: "Corsi Abilitanti all'esercizio della professione",
            url: "https://www.nailshopping.it/product-tag/corsi-con-rilascio-licenza/"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <MainFeature2
        description = "Per l'innovazione, la qualità e la vasta gamma di prodotti professionali per unghie, tutti rigorosamente testati e formulati con ingredienti naturali"
        subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={<>Perchè <HighlightedText>Noi ?</HighlightedText></>}
        statistics={[
          {
            key: "Ordini",
            value: "75000+",
          },
          {
            key: "Clienti",
            value: "8000+"
          },
          {
            key: "Insegnanti",
            value: "10+"
          }
        ]}
        primaryButtonText="Vai allo Shop"
        primaryButtonUrl="https://www.nailshopping.it"
        imageInsideDiv={false}
        imageSrc="https://www.nailshopping.it/wp-content/uploads/1816-ALC08479-1200x800.jpg"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <Testimonial
        subheading=""
        testimonials={[
          {imageSrc:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            quote: "Da quando uso i prodotti di Charm Nails, le mie clienti sono entusiaste! Il Gel UV e i Gel Colorati non solo offrono un'ampia scelta di colori, ma garantiscono anche una durata e una qualità eccezionali. Sono molto soddisfatta della loro linea di prodotti e del servizio clienti sempre disponibile.",
            customerName: "Roberta Vinci"
          },
          {imageSrc:
            "https://algogenes.com/wp-content/uploads/2020/08/image-12-300x300.jpeg",
            quote: "La qualità e l'innovazione dei prodotti Charm Nails mi hanno completamente conquistato. La vasta gamma di colori e la facilità di applicazione dei loro gel rendono ogni trattamento un'esperienza unica. Inoltre, il fatto che siano clinicamente testati mi dà grande sicurezza.",
            customerName: "Francesca Murri"
          },
          {imageSrc:
            "https://algogenes.com/wp-content/uploads/2020/08/image-9-300x300.jpeg",
            quote: "Ho provato diversi marchi per la ricostruzione delle unghie, ma Charm Nails si distingue nettamente. I loro prodotti sono di alta qualità e molto delicati sulle unghie naturali. Inoltre, apprezzo molto il loro impegno nell'utilizzo di ingredienti naturali. Il servizio clienti è sempre pronto a offrire assistenza e consigli.",
            customerName: "Davide Bartoli"
          }
        ]} 
        heading={<>I clienti ci <HighlightedText>adorano.</HighlightedText></>}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
